import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAboutModal, toggleSupportModal } from '../reducers';
import styled from 'styled-components';
import InfoPopup from './InfoPopup';

const Wrapper = styled.div`
  width: 100%;
  height: 2rem;
  flex: 0 1 auto;
  background-color: #3f4f5c;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Link = styled.span`
  color: white;
  cursor: pointer;
`;

const Separator = styled.span`
  margin: 0px 8px;
  color: white;
`;

const ContactContent = () => (
  <div>
    TV Chart was made by Benjamin M.
    <br />
    <br />
    If you have any suggestions, or you've found an inaccuracy, you can contact me at benmiz96@gmail.com
    <br />
    <br />
  </div>
);

const SupportContent = () => (
  <div>
    If you'd like to help keep TV Chart running and ad-free, please consider{' '}
    <a href="https://www.patreon.com/tvchart" target="_blank">
      supporting us on Patreon
    </a>
    . <br></br>
    Thanks :)
  </div>
);

export default () => {
  const isContactOpen = useSelector((state) => state.aboutModalOpen);
  const isSupportOpen = useSelector((state) => state.supportModalOpen);
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Link onClick={() => dispatch(toggleAboutModal())}>Contact</Link>
      <Separator>•</Separator>
      <Link onClick={() => dispatch(toggleSupportModal())}>Patreon</Link>
      <InfoPopup
        isOpen={isContactOpen}
        togglePopup={toggleAboutModal}
        title={'Contact Me'}
        content={<ContactContent />}
      />
      <InfoPopup
        isOpen={isSupportOpen}
        togglePopup={toggleSupportModal}
        title={'Enjoying TV Chart?'}
        content={<SupportContent />}
      />
    </Wrapper>
  );
};
