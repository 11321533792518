import React from "react";
import { useSelector } from "react-redux";
import Header from "./Header";
import Graph from "./Graph";
import styled from "styled-components";
import Footer from "./Footer";

const Wrapper = styled.div`
  transition: opacity 0.5s;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const BigTitle = styled.h1`
  font-size: 3.5rem;
  @media (max-width: 700px) {
    font-size: 2.5rem;
  }
  color: white;
  text-align: center;
  font-weight: 300;
  line-height: 1.2;
  flex: 0 1 auto;
`;

export default () => {
  const selectedSeason = useSelector((state) => state.selectedSeason);
  const seriesTitle = useSelector((state) => state.seriesTitle);

  return (
    <Wrapper>
      <Header />
      <BigTitle>
        {seriesTitle}: {selectedSeason === 0 ? "All Episodes" : "Season " + selectedSeason}
      </BigTitle>
      <Graph />
      <Footer />
    </Wrapper>
  );
};
