import React, { useEffect, useState } from 'react';
import { fetchSeriesData } from '../actions'
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const suggestedTitles = ["Game of Thrones", "Dexter", "The Office", "Rick and Morty", "Stranger Things",
  "The Walking Dead", "House of Cards", "Westworld", "True Detective", "Black Mirror", "Breaking Bad", "South Park"];

const randomInt = (max) => {
  return Math.floor(Math.random() * max);
}

const getNewIndex = (oldIndex) => {
  let newIndex;
  do {
    newIndex = randomInt(suggestedTitles.length);
  } while(oldIndex === newIndex);
  return newIndex;
}

const SuggestionItem = styled.a`
  cursor: pointer;
  transition: opacity 0.5s;
  font-weight: bold;
  opacity: ${({isVisible}) => isVisible ? 1 : 0};
`;

const SuggestionsWrapper = styled.div`
  position: relative;
  margin-top: 1rem;
  left: 25%;
  color: white;
`;

const Suggestions = () => {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(randomInt(suggestedTitles.length));
  const [visible, setVisible] = useState(true);

  const changeSuggestion = () => {
    setTimeout(() => {
      setIndex(getNewIndex(index));
    }, 500)
    setVisible(false);
  }

  useEffect(() => {
    setTimeout(changeSuggestion, 4000);
    setVisible(true);
  }, [index]);

  const handleClick = () => {
    dispatch(fetchSeriesData(suggestedTitles[index]));
  };

  return (
    <SuggestionsWrapper>
      Or try:
      <SuggestionItem
      isVisible={visible}
      onClick={handleClick}>
        {` ${suggestedTitles[index]}`}
      </SuggestionItem>
    </SuggestionsWrapper>
  );
};

export default Suggestions;