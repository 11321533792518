export const getAutoCompleteList = (prefix, knownTitles) => {
    const list = [];
    if (!prefix || prefix === '') {
        return list;
    }
    for(let i = 0; i < knownTitles.length; i++) {
        if (!knownTitles[i]) {
            continue;
        }
        const idx = knownTitles[i].toLowerCase().indexOf(prefix.toLowerCase());
        if(idx > -1) {
            list.push({title: knownTitles[i], idx });
        }
    }
    list.sort((a, b) => {
        if (a.idx < b.idx) {
            return -1;
        } else if(a.idx > b.idx) {
            return 1;
        } else{
            return 0;
        }
    });
    return list.slice(0, 5).map(obj => obj.title);
};

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);