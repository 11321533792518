import { createSlice } from '@reduxjs/toolkit';

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        seriesTitle: '', 
        seriesData: [],
        isLoading: false, 
        error: false, 
        errorMessage: '',
        aboutModalOpen: false,
        homeScreen: true,
        selectedSeason: 0,
        zommedIn: false,
        searchText: '',
        autoCompleteList: [],
        scatter: false,
        knownTitles: null,
        supportModalOpen: false
    },
    reducers: {
        updateLoadingStatus: (state, action) => { state.isLoading = action.payload; },
        displayError: (state, action) => { 
            state.error = true;
            state.errorMessage = action.payload;
            state.isLoading = false;
            state.searchText = '';
        },
        closeError: (state) => { state.error = false; },
        updateSeriesData: (state, action) => {
            const { title, seasons } = action.payload;
            state.seriesTitle = title;
            state.seriesData = seasons;
            state.isLoading = false;
            state.homeScreen = false;
            state.selectedSeason = 0;
            state.autoCompleteList = [];
        },
        toggleAboutModal: (state) => { state.aboutModalOpen = !state.aboutModalOpen; },
        goToHomeScreen: (state) => { state.homeScreen = true; },
        selectSeason: (state, action) => {
            state.selectedSeason = action.payload;
            if(action.payload !== 0){
                state.colorSeasons = false;
            }
        },
        toggleZoom: (state) => { state.zoomedIn = !state.zoomedIn; },
        updateSearchText: (state, action) => { state.searchText = action.payload; },
        updateAutoCompleteList: (state, action) => { state.autoCompleteList = action.payload; },
        setKnownTitles: (state, action) => { state.knownTitles = action.payload; },
        toggleScatter: (state) => { state.scatter = !state.scatter; },
        toggleColorSeasons: (state) => {
            state.colorSeasons = !state.colorSeasons;
            if(state.colorSeasons) {
                state.selectedSeason = 0;
            }
        },
        toggleSupportModal: (state) => { state.supportModalOpen = !state.supportModalOpen; },
    }
});

export const { updateLoadingStatus, displayError, closeError, updateSeriesData, toggleAboutModal, goToHomeScreen, selectSeason, toggleZoom, updateSearchText, updateAutoCompleteList, toggleScatter, setKnownTitles, toggleColorSeasons, toggleSupportModal } = mainSlice.actions;


export default mainSlice.reducer;
