import React, { useState } from 'react';
import loadingIcon from '../assets/menuIcon.svg';
import { useSelector, useDispatch } from 'react-redux'
import { toggleScatter, toggleZoom, toggleColorSeasons } from '../reducers'
import styled from 'styled-components';

const SettingsMenuWrapper = styled.div`
  background-color: #48585f;
  padding: 1rem;
  padding-bottom: 0;
  position: absolute;
  left: -4rem;
  z-index: 5;
  border-radius: 5;
  transition: opacity .25s;
  opacity: ${({isOpen}) => isOpen ? 1 : 0};
  justify-content: center;
  align-items: center;
  pointer-events: ${({isOpen}) => isOpen ? 'auto' : 'none'};
`;

const SettingsIcon = styled.img`
  width: 2rem;
  height: 2rem;
  fill: white;
  cursor: pointer;
  transition: transform 1s;
  transform: ${({isOpen}) => isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const SettingsButton = styled.div`
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${({isOn}) => isOn ? '#28a745' : '#38e896'};
  color: ${({isOn}) => isOn ? 'white' : '#38e896'};
  cursor: pointer;
  background-color: ${({isOn}) => isOn ? '#28a745' : 'transparent'};
  user-select: none;
  margin-bottom: 1rem;
  height: 2rem;
  width: 8.5rem;
  text-align: center;
  @media (max-width: 700px) {
    height: 2.5rem;
    width: 10rem;
    font-size: 1.2rem;
  }
`;

const SettingsMenuButton = styled.div`
  position: relative;
  @media (max-width: 700px) {
    padding: 0 .5rem;
  }
`;

let timeout;

const SettingsMenu = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const scatterOn = useSelector(state => state.scatter);
  const zoomedIn = useSelector(state => state.zoomedIn);
  const colorSeasons = useSelector(state => state.colorSeasons);

  const openMenu = () => {
    setOpen(true);
    clearTimeout(timeout);
  }

  return (
    <SettingsMenuButton
      onClick={openMenu}
      onMouseEnter={openMenu}
      onMouseLeave={() => {
        timeout = setTimeout(() => setOpen(false), 500);
      }}
    >
      <SettingsIcon
        src={loadingIcon}
        isOpen={open}
      />
      <SettingsMenuWrapper isOpen={open}>
        <SettingsButton
          isOn={scatterOn}
          onClick={() => dispatch(toggleScatter())}
        >
          Show Trend Line{colorSeasons ? 's' : ''}
        </SettingsButton>
        <SettingsButton
          isOn={colorSeasons}
          onClick={() => dispatch(toggleColorSeasons())}
        >
          Color Seasons
        </SettingsButton>
        <SettingsButton
          isOn={zoomedIn}
          onClick={() => dispatch(toggleZoom())}
        >
          Scale Y-Axis
        </SettingsButton>
      </SettingsMenuWrapper>
    </SettingsMenuButton>
  );
}

export default SettingsMenu;