import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { selectSeason } from '../reducers'

const SeasonSelector = () => {
  const dispatch = useDispatch();
  const selectedSeason = useSelector(state => state.selectedSeason);
  const numOfSeasons = useSelector(state => state.seriesData.length);

  var options = [];
  options.push(<option value={0}>All seasons</option>);
  for (let i = 1; i <= numOfSeasons; i++) {
    options.push(<option value={i}>Season {i}</option>);
  }
  return (
    <form className="mx-2">
      <select className="form-control-sm" value={selectedSeason} onChange={(e) => dispatch(selectSeason(parseInt(e.target.value)))}>
        {options}
      </select>
    </form>
  );
};

export default SeasonSelector;