import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default ({ isOpen, togglePopup, title, content }) => {
  const dispatch = useDispatch();
  return (
    <Modal isOpen={isOpen} toggle={() => dispatch(togglePopup())}>
      <ModalHeader toggle={() => dispatch(togglePopup())}>{title}</ModalHeader>
      <ModalBody>
        {content}
      </ModalBody>
    </Modal>
  );
};
