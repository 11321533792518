import { updateLoadingStatus, displayError, updateSeriesData, updateSearchText, setKnownTitles, updateAutoCompleteList } from './reducers';
import { getAutoCompleteList } from './utils';

const API_PREFIX = "https://us-central1-tvchart2.cloudfunctions.net/getRatingData";

export const fetchSeriesData = (title) => async (dispatch, getState) => {
  if (!title) {
    title = getState().searchText;
    if(!title) {
      return;
    }
  }
  try {
    dispatch(updateLoadingStatus(true));
    title = title.toLowerCase().replace(/\./g, '');
    const res = await fetch(getAPIUrl(title));
    if(res.status === 500) {
      const errorMessage = await res.text();
      dispatch(displayError(errorMessage));
    } else {
      const seriesData = await res.json();
      window.parent.postMessage('seriesDataLoaded', '*');
      dispatch(updateSeriesData(seriesData));
      window.history.pushState(title, title, encodeURIComponent(title));
    } 
  } catch (err) {
    console.log(err);
    dispatch(displayError("Can't access the server. Please try again later."));
  } finally {
    dispatch(updateSearchText(''));
  }
};

export const fetchAutoCompleteTitles = async (dispatch, getState) => {
  try {
    const res = await fetch(API_PREFIX + '/titles');
    if(res.status !== 500) {
      const titles = await res.json();
      dispatch(setKnownTitles(titles));
      const searchText = getState().searchText;
      dispatch(updateAutoCompleteList(getAutoCompleteList(searchText, titles))); 
    } 
  } catch (err) {
    console.log(err);
  }
};

const getAPIUrl = (title) => {
  return `${API_PREFIX}?seriesTitle=${title}`;
}