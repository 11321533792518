import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import HomeScreen from './HomeScreen';
import GraphScreen from './GraphScreen';
import { fetchAutoCompleteTitles } from '../actions';

const App = () => {
  const dispatch = useDispatch();
  const homeScreen = useSelector(state => state.homeScreen);
  useEffect(() => {
    dispatch(fetchAutoCompleteTitles);
  }, []);
  if (homeScreen)
    return <HomeScreen />;
  else
    return <GraphScreen />;
};

export default App;

