import React from 'react';

const getLabel = (episode) => {
  return "S" + episode.season + "E" + episode.episode + ": " + episode.name;
};

const ChartTooltip = (props) => {
  if (!props || !props.payload || props.payload.length === 0) {
    return;
  }
  const episode = props.payload[0].payload;
  return (
    <div style={{ padding: 10, backgroundColor: 'white', borderRadius: 3, fontSize: '1rem' }}>
      <div style={{color: '#212529'}}>
        {getLabel(episode)}
      </div>
      <div style={{color: '#32c17e'}}>
        {'rating: ' + episode.rating}
      </div>
    </div>
  );
};

export default ChartTooltip;
