import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import loadingIcon from '../assets/loadingIcon.svg';
import ErrorModal from './ErrorModal';
import { fetchSeriesData } from '../actions'
import { closeError, updateSearchText, updateAutoCompleteList } from '../reducers'
import AutoComplete from './AutoComplete';
import { getAutoCompleteList } from '../utils'
import styled from 'styled-components';

const SearchForm = styled.form`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-right: 0.5rem;
  ${({isWide}) => isWide && `
    width: 400px;
  `}
`;

const SearchBox = ({preloadedText, wide}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.isLoading);
  const error = useSelector(state => state.error);
  const errorMessage = useSelector(state => state.errorMessage);
  const searchText = useSelector(state => state.searchText);
  const knownTitles = useSelector(state => state.knownTitles);

  useEffect(() => {
    if(preloadedText && preloadedText !== '') {
      dispatch(fetchSeriesData(preloadedText));
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(fetchSeriesData());
  };
  
  const handleChange = (event) => {
    const text = event.target.value;
    dispatch(updateSearchText(text));
    if(knownTitles !== null) {
      const list = getAutoCompleteList(text, knownTitles);
      dispatch(updateAutoCompleteList(list));
    }
  }

  const Button = <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>;
  const LoadingIcon = <img src={loadingIcon} width={40} />;
  return (
    <div>
      <SearchForm onSubmit={(e) => handleSubmit(e)}>
        <InputWrapper isWide={wide}>
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search for a TV show"
            value={searchText}
            aria-label="Search"
            onChange={event => {handleChange(event)}}
            disabled={isLoading}
            autoComplete="off"
          />
          <AutoComplete/>
        </InputWrapper>
        {isLoading ? LoadingIcon : Button}
      </SearchForm>
      <ErrorModal open={error} toggle={() => dispatch(closeError())} errorMsg={errorMessage} />
    </div>
  );
};

export default SearchBox;