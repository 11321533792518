import React from 'react';
import { useDispatch } from 'react-redux'
import { goToHomeScreen } from '../reducers'
import SearchBox from './SearchBox';
import SeasonSelector from './SeasonSelector';
import SettingsMenu from './SettingsMenu';
import styled from 'styled-components';
import ShareButton from './ShareButton';

const redirectToHomescreen = (dispatch) => {
  window.history.pushState('home', 'home', '/');
  dispatch(goToHomeScreen());
}

const NavBar = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: .5rem 1rem;
  justify-content: flex-end;
  background-color: #343a40;
  flex: 0 1 auto;
`;

const Logo = styled.span`
  display: inline-block;
  padding: .3125rem 0;
  font-size: 1.25rem;
  color: white;
  margin-right: auto;
  cursor: pointer;
`;

const Header = () => {
  const dispatch = useDispatch();
  return (
  <NavBar>
    <Logo onClick={() => redirectToHomescreen(dispatch)}>
      TV Chart
    </Logo>
    <ShareButton />
    <SettingsMenu />
    <SeasonSelector/>
    <SearchBox/>               
  </NavBar>
)};



export default Header;