import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ErrorModal = ({open, toggle, errorMsg}) => (
  <Modal isOpen={open} toggle={toggle} centered={true}>
    <ModalBody>
      {errorMsg}
    </ModalBody>
    <ModalFooter>
      <Button color="info" onClick={toggle}>Okay</Button>{' '}
    </ModalFooter>
  </Modal>
);

export default ErrorModal;