import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { updateSearchText } from '../reducers'
import { fetchSeriesData } from '../actions'
import styled from 'styled-components';

const AutoCompleteWrapper = styled.div`
  width: 100%;
  background-color: white;
  z-index: 1;
  position: absolute;
  margin-top: 2px;
  border-radius: 0.25rem;
  padding: 10px 0px;
`;

const AutoCompleteItem = styled.div`
  padding: 0.3rem 1rem;
  cursor: pointer;
  ${({isSelected}) => isSelected && `
    background-color: #c9c9c9;
  `}
`;


const AutoComplete = () => {
  const dispatch = useDispatch();
  const [selectedIndex, _setSelectedIndex] = useState(0);
  const list = useSelector(state => state.autoCompleteList);
  const listLength = list.length;

  const selectedIndexRef = React.useRef(selectedIndex);
  const setSelectedIndex = data => {
    selectedIndexRef.current = data;
    _setSelectedIndex(data);
  };

  const selectItem = (index) => {
    let text;
    if(index === 0) {
      text = '';
    } else {
      text = list[index-1];
    }
    dispatch(updateSearchText(text));
    setSelectedIndex(index);
  };

  const handleKeyPress = (event) => {
    let dif;
    if (event.key === 'ArrowDown') {
      dif = 1;
    } else if (event.key === 'ArrowUp') {
      dif = -1;
    } else {
      return;
    }
    let index = (selectedIndexRef.current + dif) % (listLength + 1);
    if (index === -1 ) {
      index = listLength;
    }
    selectItem(index);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {window.removeEventListener('keydown', handleKeyPress);};
  });

  return listLength > 0 && (
    <AutoCompleteWrapper
      tabIndex={0}
    >
      {list.map((title, i) => (
        <AutoCompleteItem
          isSelected={i + 1 === selectedIndex}
          onMouseEnter={selectItem.bind(null, i + 1)}
          onMouseLeave={selectItem.bind(null, 0)}
          onClick={() => dispatch(fetchSeriesData()) }
        >
          {title}
        </AutoCompleteItem>
      ))}
    </AutoCompleteWrapper>
  );
}

export default AutoComplete;