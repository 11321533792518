import React from 'react';
import SearchBox from './SearchBox';
import Suggestions from './Suggestions';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #343a40;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  transition: opacity 0.5s;
`;

const Title = styled.h1`
  color: white;
  margin-top: 30vh;
  margin-bottom: 30px;
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
`;

const HomeScreen = () => (
  <Wrapper>
    <Title>TV Chart</Title>
    <div>
      <SearchBox wide preloadedText={getPath()}/>
      <Suggestions/>
    </div>
  </Wrapper>
);

const getPath = () => {
  const pathArr = window.location.pathname.split('/');
  const searchText = pathArr.length === 0 ? '' : decodeURIComponent(pathArr[1]);
  return searchText;
}

export default HomeScreen;