import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import shareIcon from '../assets/share.svg';
import loadingIcon from '../assets/loadingIcon.svg';

const ShareIcon = styled.img`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  margin: 0 0.5rem;
`;

const ShareButton = () => {
  const seriesTitle = useSelector(state => state.seriesTitle);
  const [inProgress, setInProgress] = useState(false);

  const canShare = !!navigator.share;
  if (!canShare) {
    return null;
  }

  const share = () => {
    setInProgress(true);
    navigator.share({
      url: window.location.href,
      title: `${seriesTitle} on TV Chart`
    }).then(() => {
      window.ga('send', 'event', 'Share', 'sent');
    }).finally(() => {
      setInProgress(false);
      window.ga('send', 'event', 'Share', 'clicked');
    });
  };

  return (
    inProgress ?
      <img src={loadingIcon} width={40} /> :
      <ShareIcon src={shareIcon} onClick={() => share()} />
    );
}

export default ShareButton;