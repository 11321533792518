import React, { useMemo } from 'react';
import { useSelector } from 'react-redux'
import {
  LineChart,
  Line, ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip,
  ReferenceLine,
  ScatterChart,
  Scatter
} from 'recharts';
import ChartTooltip from './ChartTooltip';
import styled from 'styled-components';
import { isSafari } from '../utils'

const COLORS = ["#35D987", "#1BE7FF"];

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: 2rem;
  margin: 0px auto;
  flex: 1 0 auto;
`;

const Label = styled.p`
  position: absolute;
  color: #dce5ed;
`;

const YAxisLabel = styled(Label)`
  top: -20px;
  left: 24px;
`;

const XAxisLabel = styled(Label)`
  top: ${({ position }) => position ? `${position}px` : '95%'};
  right: 2vw;
`;

const getStrokeColor = (seasonNum) => {
  if (seasonNum === 0) {
    return "#32C17E";
  }
  return COLORS[(seasonNum - 1) % COLORS.length]
};

const getScatterChart = (data, seasonNum) => <Scatter line={{ strokeWidth: 2 }} lineType="fitting" nameKey="name" fill={getStrokeColor(seasonNum)} data={data} dataKey="rating" />;

const getLineChart = (seasonNum) => (<Line type="monotone" nameKey="name" strokeWidth={2} stroke={getStrokeColor(seasonNum)}
                                      fill={seasonNum ? getStrokeColor(seasonNum) : 'white'}
                                      dataKey={seasonNum ? `season${seasonNum}` : 'rating'} />);

const Graph = () => {
  const zoomedIn = useSelector(state => state.zoomedIn);
  const selectedSeason = useSelector(state => state.selectedSeason);
  const rawSeriesData = useSelector(state => state.seriesData);
  const scatter = useSelector(state => state.scatter);
  const colorSeasons = useSelector(state => state.colorSeasons);
  
  const dataBySeason = useMemo(() => {
    const dataBySeason = [];
    let episodeCount = 1;
    rawSeriesData.forEach((seasonData, i) => {
      dataBySeason.push([]);
      seasonData.forEach((episodeData, j) => {
        const episode = Object.assign({
          episodeNum: episodeCount++,
          ['season' + (i + 1)]: episodeData.rating
        }, episodeData);
        if (i !== 0 && j === 0) {
          episode['season' + i] = episodeData.rating;
        }
        dataBySeason[i].push(episode);
      });
    });
    return dataBySeason;
  }, [rawSeriesData]);

  const seriesData = selectedSeason === 0 ? [].concat.apply([], dataBySeason) : dataBySeason[selectedSeason - 1];

  const Chart = scatter ? ScatterChart : LineChart;
  let InnerChart;
  if (colorSeasons) {
    InnerChart = dataBySeason.map((seasonData, i) => {
      const seasonNum = i + 1;
      return scatter ? getScatterChart(seasonData, seasonNum) : getLineChart(seasonNum);
    });
  } else {
    InnerChart = scatter ? getScatterChart(seriesData, 0) : getLineChart(0);
  }

  return (
    <Wrapper>
      <ResponsiveContainer className="chart" width="100%" height={isSafari() ? 700 : "95%"}>
        <Chart data={seriesData}>
          {InnerChart}
          <Tooltip content={ChartTooltip} />
          <XAxis
            allowDuplicatedCategory={!scatter}
            dataKey={selectedSeason === 0 ? "episodeNum" : "episode"}
            padding={{ left: 10 }}
            stroke="#dce5ed" />
          <YAxis
            type="number"
            domain={[dataMin => (zoomedIn ? Math.floor(dataMin) : 0), dataMax => (zoomedIn ? Math.ceil(dataMax) : 10)]}
            allowDecimals={false}
            stroke="#dce5ed"
            tickCount={11}
          />
          {getReferenceLines(dataBySeason, selectedSeason)}
        </Chart>
      </ResponsiveContainer>
      <YAxisLabel>Rating</YAxisLabel>
      <XAxisLabel position={isSafari() && 700}>Episode</XAxisLabel>
    </Wrapper>
  );
}

const getReferenceLines = (seriesData, selectedSeason) => {
  if (seriesData === undefined)
    return;
  if (selectedSeason !== 0)
    return [];
  const referenceLines = [];
  referenceLines.push(<ReferenceLine x={1} strokeDasharray="5 5" stroke="#678296" />);
  let seasonsStart = 1;
  for (let i = 0; i < seriesData.length - 1; i++) {
    seasonsStart = seasonsStart + seriesData[i].length;
    referenceLines.push(<ReferenceLine x={seasonsStart} strokeDasharray="5 5" stroke="#678296" />);
  }
  return referenceLines;
}

export default Graph;